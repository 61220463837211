import { Radio } from '@mantine/core'
import { DatePickerInput } from '@mantine/dates'
import { QuestionnaireItem } from '@medplum/fhirtypes'
import DOMPurify from 'dompurify'
import React from 'react'
import { Control, Controller, FieldValues, UseFormRegister } from 'react-hook-form'

import styles from './CustomQuestionnaireItem.module.scss'

interface Props {
	register: UseFormRegister<FieldValues>
	item: QuestionnaireItem
	control: Control
}

const CustomQuestionnaireItem = ({ item, register, control }: Props) => {
	let textJSX = (() => {
		if (item.type === 'display' && item.text) {
			return (
				<div
					className={styles.evalText}
					dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item.text) }}
				></div>
			)
		} else {
			return item.text
		}
	})()

	let inputJSX = (() => {
		if (item.type === 'choice') {
			return item.answerOption?.map((ansOpt) => {
				return (
					<React.Fragment key={ansOpt.id}>
						<Radio
							{...register(item.linkId!)}
							type="radio"
							value={ansOpt.valueString}
							label={ansOpt.valueString}
						/>
					</React.Fragment>
				)
			})
		} else if (item.type === 'date') {
			return (
				<Controller
					control={control}
					name={item.linkId!}
					render={({ field: { onChange, value } }) => (
						<DatePickerInput onChange={onChange} value={value} />
					)}
				/>
			)
		} else {
			return null
		}
	})()

	return (
		<div className={styles.itemContainer}>
			{textJSX}
			<div className={styles.choicesContainer}>{inputJSX}</div>
		</div>
	)
}

export default CustomQuestionnaireItem
