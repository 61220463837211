import { Suspense } from 'react'
import { RouterProvider, createBrowserRouter } from 'react-router-dom'

import { LoaderScreen } from 'components/LoaderScreen'
import { ErrorPage } from 'pages/ErrorPage'
import { LoginPage } from 'pages/LoginPage'
import { Questionnaire } from 'pages/Questionnaire'
import { RegisterPage } from 'pages/RegisterPage'

import './App.scss'
import { Landing } from './pages/Landing'

const router = createBrowserRouter([
	{
		path: '/',
		element: <Landing />,
		errorElement: <ErrorPage />,
	},
	{
		path: '/login',
		element: <LoginPage />,
	},
	{
		path: '/register',
		element: <RegisterPage />,
	},
	{
		path: '/questionnaire',
		element: <Questionnaire />,
	},
])

function App() {
	return (
		<Suspense fallback={<LoaderScreen />}>
			<RouterProvider router={router} />
		</Suspense>
	)
}

export default App
