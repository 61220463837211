import { Button } from '@mantine/core'
import { ProfileResource, createReference, getReferenceString } from '@medplum/core'
import { Patient, QuestionnaireResponseItem } from '@medplum/fhirtypes'
import { useMedplum, useMedplumProfile } from '@medplum/react'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import CustomQuestionnaire from 'components/CustomQuestionnaire/CustomQuestionnaire'
import { LoaderScreen } from 'components/LoaderScreen'

import styles from './Questionnaire.module.scss'

export const Questionnaire = () => {
	const [responseExists, setResponseExists] = useState(false)
	const medplum = useMedplum()
	const profile = useMedplumProfile() as Patient

	const questionnaire = medplum
		.readResource('Questionnaire', process.env.REACT_APP_QUESTIONNAIRE_ID!)
		.read()

	const qsnResp = medplum.searchResources('QuestionnaireResponse').read()

	function processResponse(formResult: any): QuestionnaireResponseItem[] {
		let procResArr: QuestionnaireResponseItem[] = []

		for (const entry in formResult) {
			const questionnaireItem = questionnaire.item?.find((item) => item.linkId === entry)
			let respObj: QuestionnaireResponseItem = {
				linkId: questionnaireItem?.linkId,
				text: questionnaireItem?.text,
			}
			if (questionnaireItem?.type === 'choice') {
				respObj.answer = [
					{
						valueString: formResult[entry],
					},
				]
			} else if (questionnaireItem?.type === 'date') {
				respObj.answer = [
					{
						valueDate: formResult[entry],
					},
				]
			}
			procResArr.push(respObj)
		}
		return procResArr
	}

	async function handleQsnResponse(qsnResponse: any) {
		await medplum.createResource({
			resourceType: 'QuestionnaireResponse',
			item: processResponse(qsnResponse),
			questionnaire: getReferenceString(questionnaire),
			subject: createReference(profile as ProfileResource),
			source: createReference(profile as ProfileResource),
			authored: new Date().toISOString(),
			status: 'completed',
		})
		setResponseExists(true)
	}

	useEffect(() => {
		if (qsnResp.length > 0) {
			setResponseExists(true)
		} else {
			setResponseExists(false)
		}
	}, [qsnResp])

	if (medplum.isLoading()) return <LoaderScreen />

	if (responseExists) {
		return (
			<div className={styles.pageContainer}>
				<h2 className={styles.finalTitle}>
					Thank you for taking an interest in IgG Testing. <br /> We'll be reaching out shortly to
					let you know if you qualify.
				</h2>
				<Link to="/" className={styles.requestButton}>
					Visit SiPhox Health
				</Link>
				<button className={styles.signoutLink} onClick={() => medplum.signOut()}>
					Sign out
				</button>
			</div>
		)
	}

	return (
		<div className={styles.pageContainer}>
			<div className={styles.questionnaireWrapper}>
				<CustomQuestionnaire items={questionnaire.item} onSubmit={handleQsnResponse} />
			</div>
			<Button radius="md" size="md" variant="light" color="red" onClick={() => medplum.signOut()}>
				Sign out
			</Button>
		</div>
	)
}
