import { Button, Card, Checkbox, PasswordInput, TextInput } from '@mantine/core'
import { OperationOutcomeIssue } from '@medplum/fhirtypes'
import { getRecaptcha, initRecaptcha, useMedplum, useMedplumProfile } from '@medplum/react'
import { useForm } from 'react-hook-form'
import { Navigate, useNavigate } from 'react-router-dom'

import styles from './RegisterPage.module.scss'

export const RegisterPage = () => {
	const navigate = useNavigate()
	const medplum = useMedplum()
	const profile = useMedplumProfile()
	const {
		register,
		handleSubmit,
		formState: { errors },
		setError,
	} = useForm()

	if (process.env.REACT_APP_RECAPTCHA_KEY) {
		initRecaptcha(process.env.REACT_APP_RECAPTCHA_KEY)
	}

	async function onSubmit(data: any) {
		try {
			const recaptchaToken = await getRecaptcha(process.env.REACT_APP_RECAPTCHA_KEY!)
			let newUserResponse = await medplum.startNewUser({
				projectId: process.env.REACT_APP_MEDPLUM_PROJECT_ID,
				recaptchaSiteKey: process.env.REACT_APP_RECAPTCHA_KEY,
				recaptchaToken: recaptchaToken,
				firstName: data.firstName,
				lastName: data.lastName,
				email: data.email,
				password: data.password,
				remember: data.remember,
			})
			if (newUserResponse.code) {
				await medplum.processCode(newUserResponse.code)
				navigate('/questionnaire')
			} else if (newUserResponse.login) {
				let newPatientResponse = await medplum.startNewPatient({
					login: newUserResponse.login,
					projectId: process.env.REACT_APP_MEDPLUM_PROJECT_ID!,
				})
				await medplum.processCode(newPatientResponse.code as string)
				navigate('/questionnaire')
			}
		} catch (err: any) {
			if (err.outcome && err.outcome.resourceType === 'OperationOutcome') {
				err.outcome.issue.forEach((issueObj: OperationOutcomeIssue) => {
					if (issueObj.expression?.includes('password')) {
						setError('password', { type: 'custom', message: issueObj.details?.text })
					} else if (issueObj.expression?.includes('email')) {
						setError('email', { type: 'custom', message: issueObj.details?.text })
					}
				})
			}
		}
	}

	if (profile) return <Navigate to="/questionnaire" />

	return (
		<div className={styles.pageContainer}>
			<Card shadow={'md'} radius={'md'} className={styles.registerFormWrapper}>
				<form className={styles.registerForm} onSubmit={handleSubmit(onSubmit)}>
					<h2>Please create an account to proceed</h2>
					<div className={styles.fieldsContainer}>
						<TextInput
							{...register('firstName', {
								required: { value: true, message: 'Please fill in your first name' },
							})}
							placeholder="First name"
							label="First name"
							error={errors.firstName && String(errors.firstName.message)}
							withAsterisk
						/>
						<TextInput
							{...register('lastName', {
								required: { value: true, message: 'Please fill in your last name' },
							})}
							placeholder="Last name"
							label="Last name"
							error={errors.lastName && String(errors.lastName.message)}
							withAsterisk
						/>
						<TextInput
							{...register('email', {
								required: { value: true, message: 'Please fill in your email' },
								pattern: {
									value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
									message: 'Please enter a valid email',
								},
							})}
							placeholder="Email"
							label="Email"
							error={errors.email && String(errors.email.message)}
							withAsterisk
						/>
						<PasswordInput
							{...register('password', {
								required: { value: true, message: 'Please fill in your password' },
							})}
							placeholder="Password"
							label="Password"
							error={errors.password && String(errors.password.message)}
							withAsterisk
						/>
						<span className={styles.loginLink}>
							Already have an account? <a href="/login"> Sign in</a>
						</span>
					</div>
					<div className={styles.bottomRow}>
						<Checkbox {...register('rememberMe')} label="Remember me" size="xs" />
						<Button type="submit">Sign up</Button>
					</div>
				</form>
			</Card>
		</div>
	)
}
