import { SignInForm, useMedplumProfile } from '@medplum/react'
import { Navigate, useNavigate } from 'react-router-dom'

import styles from './LoginPage.module.scss'

export const LoginPage = () => {
	const navigate = useNavigate()

	const profile = useMedplumProfile()

	if (profile) return <Navigate to="/questionnaire" />

	return (
		<div className={styles.pageContainer}>
			<div className={styles.signInFormWrapper}>
				<SignInForm
					onSuccess={() => navigate('/questionnaire')}
					onRegister={() => navigate('/register')}
					projectId={process.env.REACT_APP_MEDPLUM_PROJECT_ID}
				>
					<h1>Sign in to Medplum</h1>
				</SignInForm>
			</div>
		</div>
	)
}
