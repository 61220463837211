import { Button, Progress } from '@mantine/core'
import { QuestionnaireItem } from '@medplum/fhirtypes'
import { useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'

import CustomQuestionnaireItem from 'components/CustomQuestionnaireItem/CustomQuestionnaireItem'
import { LoaderScreen } from 'components/LoaderScreen'

import styles from './CustomQuestionnaire.module.scss'

interface Props {
	items?: QuestionnaireItem[]
	onSubmit: SubmitHandler<any>
}

const CustomQuestionnaire = ({ items, onSubmit }: Props) => {
	const [currentItemIdx, setCurrentItemIdx] = useState(0)

	const { register, handleSubmit, watch, control } = useForm()

	function checkDisabled(item: QuestionnaireItem) {
		if (item.type === 'display') return false
		return !watch(item.linkId!)
	}

	if (!items) {
		return <LoaderScreen />
	}

	return (
		<div className={styles.formContainer}>
			<Progress value={(currentItemIdx / items.length) * 100} />
			<form className={styles.form}>
				<CustomQuestionnaireItem
					item={items[currentItemIdx]}
					register={register}
					control={control}
				/>
				<div className={styles.buttonContainer}>
					{currentItemIdx !== 0 && (
						<Button
							type="button"
							radius="md"
							size="md"
							onClick={() => setCurrentItemIdx((prevIdx) => --prevIdx)}
						>
							Previous question
						</Button>
					)}
					{currentItemIdx !== items.length - 1 ? (
						<Button
							type="button"
							radius="md"
							size="md"
							disabled={checkDisabled(items[currentItemIdx])}
							onClick={() => setCurrentItemIdx((prevIdx) => ++prevIdx)}
						>
							Next question
						</Button>
					) : (
						<Button
							color="green"
							radius="md"
							size="md"
							type="button"
							onClick={handleSubmit(onSubmit)}
						>
							Submit
						</Button>
					)}
				</div>
			</form>
		</div>
	)
}

export default CustomQuestionnaire
