import Question from 'components/Question/Question'

import styles from './QuestionsContainer.module.scss'

const QuestionsContainer = () => {
	return (
		<div className={styles.questionsContainer}>
			<Question title="When should I check my IgG levels?">
				<p>
					In general, Ig Antibody levels are checked when someone is experiencing frequent or
					recurrent infections, especially if the infections are severe or difficult to treat.
					Additionally, if you have a family history of primary immunodeficiency or other inherited
					immune disorders, or if you have been diagnosed with an autoimmune disorder or other
					chronic illness that affects your immune system, your healthcare provider may recommend
					checking your Ig Antibody levels.
				</p>
			</Question>
			<Question title="How can I qualify for a free IgG mail-in test?">
				<p>
					To qualify for a free Ig Antibody mail-in test from SiPhox, you need to fill out a short
					questionnaire. The questionnaire will ask you a few questions about your health and
					medical history. If you meet the criteria for the free test kit, we will mail it to your
					address, and you can take the test at home. However, if you do not qualify for a free
					test, you can still purchase one.
				</p>
			</Question>
			<Question title="What happens if I don't qualify for a free IgG mail-in test? Can I buy it?">
				<p>
					Yes, if you do not qualify for a free Ig Antibody mail-in test after filling out the
					questionnaire, you can still purchase the test kit. The cost of the kit is $200.
				</p>
			</Question>
			<Question title="What do low Ig Antibody levels mean?">
				<p>
					Low Ig Antibody levels may indicate that the immune system may not be functioning
					properly, specifically in terms of producing enough antibodies to fight off infections.
					This condition is known as hypogammaglobulinemia or primary immunodeficiency. Individuals
					with low Ig Antibody levels may experience frequent or recurrent infections, which can be
					severe and difficult to treat. <br />
					<br />
					Low Ig Antibody levels can be caused by a variety of factors, including genetic factors,
					certain medications, and underlying medical conditions such as autoimmune disorders or
					cancer. In some cases, the cause of low Ig Antibody levels may be unknown. Low Ig Antibody
					levels alone are not sufficient for a diagnosis, however screening for them is a first
					step toward getting diagnosed with Primary Immunodeficiency by a healthcare professional
					(typically a clinical immunologist). In order to receive a diagnosis more involved testing
					is required. It is important to note that Primary Immunodeficiency can be treated with
					immunoglobulin replacement therapy, which involves receiving regular infusions of
					antibodies to help fight off infections. Early detection and treatment of low Ig Antibody
					levels can help improve quality of life and prevent serious complications. If you discover
					you have low Ig Antibody levels, you must talk to your healthcare provider before
					considering any next steps.
				</p>
			</Question>
			<Question title="If I am identified with IgG deficiency, what are the next steps?">
				<p>
					If you are identified with Ig Antibody deficiency after taking the test, the next steps
					will depend on other factors such as your medical history and overall health. It is
					recommended that you consult with a healthcare professional who can provide you with
					appropriate guidance and treatment options. SiPhox will provide recommendations for
					relevant healthcare professionals in your area, however, we are not affiliated with any.
					<br />
					<br />
					Generally, some treatment options for Ig Antibody deficiency may include receiving regular
					infusions of immunoglobulin antibodies, taking antibiotics to prevent infections, and
					making certain lifestyle changes such as maintaining good hygiene practices and avoiding
					exposure to germs. Your healthcare professional will be able to provide you with the best
					treatment options based on your specific needs and medical history.
				</p>
			</Question>
			<Question title="Why is the test free?">
				<p>
					SiPhox is offering free Ig Antibody test kits to qualified individuals as part of its
					mission to provide affordable and accessible health testing. We are supported in this
					effort by several organizations focused on raising awareness about Primary
					Immunodeficiency. The average diagnosis of Primary Immunodeficiency takes 15 years.
					Helping people discover their deficiency sooner, means they can start treating the root
					cause sooner and living better lives.
				</p>
			</Question>
		</div>
	)
}

export default QuestionsContainer
