import { Loader } from '@mantine/core'

import styles from './LoaderScreen.module.scss'

export const LoaderScreen = () => {
	return (
		<div className={styles.pageContainer}>
			<Loader size={'xl'} />
		</div>
	)
}
