import classNames from 'classnames'
import { useEffect, useRef, useState } from 'react'

import minusSVG from 'assets/svg/minus.svg'
import plusSVG from 'assets/svg/plus.svg'

import styles from './Question.module.scss'

type Props = {
	title: string
	children: JSX.Element
}

const Question = ({ children, title }: Props) => {
	const [expanded, setExpanded] = useState<boolean>(false)
	const contentRef = useRef<HTMLDivElement>(null)

	useEffect(() => {
		if (contentRef.current) {
			if (expanded) {
				contentRef.current.style.maxHeight = contentRef.current.scrollHeight + 'px'
			} else {
				contentRef.current.style.maxHeight = '0px'
			}
		}
	}, [expanded])

	return (
		<div className={styles.questionWrapper}>
			<div className={styles.topRow} onClick={() => setExpanded((prevState) => !prevState)}>
				<span>{title}</span>
				<img src={expanded ? minusSVG : plusSVG} alt="" />
			</div>
			<div
				ref={contentRef}
				className={classNames(styles.responseParagraph, !expanded && styles.hidden)}
			>
				{children}
			</div>
		</div>
	)
}

export default Question
