import { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'

import certificationsPNG from 'assets/images/certifications.png'
import kitPNG from 'assets/images/kit.png'
import logoPNG from 'assets/images/logo.png'
import abstractFigureSVG from 'assets/svg/abstract-figure.svg'
import burgerIconSVG from 'assets/svg/burger-icon.svg'
import checkmarkSVG from 'assets/svg/checkmark.svg'
import crossSVG from 'assets/svg/cross.svg'
import fbSVG from 'assets/svg/fb_logo.svg'
import smallArrowSVG from 'assets/svg/small-arrow.svg'
import step1SVG from 'assets/svg/step1.svg'
import step2SVG from 'assets/svg/step2.svg'
import step3SVG from 'assets/svg/step3.svg'
import trustpilotFrameSVG from 'assets/svg/trustpilot-frame.svg'
import twitterSVG from 'assets/svg/twitter_logo.svg'
import ytSVG from 'assets/svg/yt_logo.svg'
import QuestionsContainer from 'components/QuestionsContainer/QuestionsContainer'

import { scrollToRef } from 'utils/DocumentUtils'

import styles from './Landing.module.scss'

export const Landing = () => {
	const [mobileMenuExpanded, setMobileMenuExpanded] = useState<boolean>(false)
	const faqRef = useRef<HTMLDivElement | null>(null)

	useEffect(() => {
		if (mobileMenuExpanded) {
			document.body.style.overflow = 'hidden'
		} else {
			document.body.style.overflow = 'unset'
		}
	}, [mobileMenuExpanded])

	return (
		<>
			<div className={styles.headerWrapper}>
				<header className={styles.header}>
					<a href="https://siphoxhealth.com/" className={styles.logoWrapper}>
						<img src={logoPNG} alt="" />
					</a>
					<div className={styles.menuWrapper}>
						<Link to="https://siphoxhealth.com/pages/partner">Partner</Link>
						<button onClick={() => scrollToRef(faqRef)}>FAQs</button>
						<Link to="https://hub.siphoxhealth.com/">Blog</Link>
						<div className={styles.separator} />
						<Link to="/login">Login</Link>
						<Link to="/register" className={styles.startButton}>
							Start your journey
						</Link>
					</div>
					<button
						className={styles.mobileMenuButton}
						onClick={() => setMobileMenuExpanded((prevState) => !prevState)}
					>
						{mobileMenuExpanded ? (
							<img src={crossSVG} alt="" />
						) : (
							<img src={burgerIconSVG} alt="" />
						)}
					</button>
				</header>
			</div>
			{mobileMenuExpanded && (
				<div className={styles.mobileMenuWrapper}>
					<div className={styles.mobileMenu}>
						<a href="https://siphoxhealth.com/pages/partner">Partner</a>
						<a href="https://siphoxhealth.com/pages/faqs">FAQs</a>
						<a href="https://hub.siphoxhealth.com/">Blog</a>
						<a href="https://siphoxhealth.com/account/login">Login</a>
						<a href="https://siphoxhealth.com/pages/pricing" className={styles.startButton}>
							Start your journey
						</a>
					</div>
				</div>
			)}
			<div className={styles.subHeaderWrapper}>
				<div className={styles.subHeader}>
					<div className={styles.labelWrapper}>
						<img src={checkmarkSVG} alt="" />
						<span>RESULTS IN 3-5 WORKING DAYS</span>
					</div>
					<div className={styles.trustpilotTag}>
						<a
							href="https://www.trustpilot.com/review/siphoxhealth.com"
							target="_blank"
							rel="noreferrer"
						>
							<img src={trustpilotFrameSVG} alt="" />
						</a>
					</div>
					<div className={styles.labelWrapper}>
						<img src={checkmarkSVG} alt="" />
						<span>ACCREDITED LAB ANALYSIS</span>
					</div>
				</div>
			</div>
			<div className={styles.panel1}>
				<h1 className={styles.title}>Find the cause and overcome frequent infections</h1>
				<p className={styles.paragraph}>
					1M Americans suffer from low Immunoglobulin Antibodies, putting them at increased risk for
					frequent infections. Uncover the truth about your Ig levels with a free* at-home test kit,
					requiring only a single drop of blood.
				</p>
				{/* <div className={styles.poweredByLabel}>
					<span>Powered by</span>
					<img src={takedaLogoPNG} alt="" />
				</div> */}
				<Link to="/register" className={styles.requestButton}>
					REQUEST IG ANTIBODY TEST KIT
				</Link>
			</div>
			<div className={styles.panel2}>
				<h2 className={styles.title}>
					Are you struggling with frequent colds, rashes and other infections?
				</h2>
				<div className={styles.separator} />
				<p className={styles.paragraph}>
					You might be suffering from low Ig Antibody, an immunoglobulin that helps prevent
					infections.
				</p>
				<p className={styles.paragraph}>
					But don't worry, we're here to help. Find out the state of your Ig levels with a painless
					- single drop of blood - test right in the comfort of your own home.{' '}
				</p>
				<p className={styles.paragraph}>
					Qualify for a free Ig mail-in test kit by filling out a short questionnaire and learn
					about your treatment options if diagnosed.
				</p>
				<Link to="/register" className={styles.requestButton}>
					REQUEST IG ANTIBODY TEST KIT
				</Link>
				<div className={styles.kitDetailsWrapper}>
					<div className={styles.kitDetailsContainer}>
						<h3 className={styles.title}>Ig Antibody mail-in test kit</h3>
						<p className={styles.paragraph}>
							Uncover the truth about your Ig Antibody levels with a mail-in test kit and take
							necessary action to boost your immune system.
						</p>
						<div className={styles.priceContainer}>
							<span className={styles.currency}>$</span>
							<span className={styles.price}>0</span>
							<span className={styles.label}>if you qualify for a free kit</span>
						</div>
						<div className={styles.separator} />
						<div className={styles.factRow}>
							<img src={checkmarkSVG} alt="" />
							<span>Painless and effortless process</span>
						</div>
						<div className={styles.factRow}>
							<img src={checkmarkSVG} alt="" />
							<span>Requires only a single drop of blood</span>
						</div>
						<div className={styles.factRow}>
							<img src={checkmarkSVG} alt="" />
							<span>All logistics taken care of</span>
						</div>
						<div className={styles.factRow}>
							<img src={checkmarkSVG} alt="" />
							<span>Results within 3-5 business days</span>
						</div>
						<div className={styles.factRow}>
							<img src={checkmarkSVG} alt="" />
							<span>Recommendations for next steps if low Ig Antibody is discovered</span>
						</div>
						<Link to="/register" className={styles.requestButton}>
							REQUEST IG ANTIBODY TEST KIT
						</Link>
					</div>
					<img className={styles.kitImage} src={kitPNG} alt="Siphox Kit" />
				</div>
			</div>
			{/* <div className={styles.panel3}>
				<h2 className={styles.title}>Identify and improve your life</h2>
				<div className={styles.separator} />
				<p className={styles.paragraph}>
					Learn how immunotherapy improved Andrew's quality of life after being diagnosed with
					immunodeficiency.
				</p>
				<div className={styles.videoContainer}>
					<img className={styles.thumbNail} src={thumbnailPNG} alt="" />
					<div className={styles.playButton}>
						<img className={styles.arrow} src={arrowSVG} alt="" />
					</div>
				</div>
			</div> */}
			<div className={styles.panel4}>
				<h2 className={styles.title}>How does it work?</h2>
				<div className={styles.separator} />
				<p className={styles.paragraph}>
					A quick and easy process that ensures as little effort as possible from you. You take the
					test, we take care of everything else.
				</p>
				<div className={styles.stepsWrapper}>
					<div className={styles.stepsContainer}>
						<div className={styles.step}>
							<img className={styles.stepIcon} src={step1SVG} alt="" />
							<h3 className={styles.title}>Collect a sample</h3>
							<p className={styles.paragraph}>
								Collect a sample with a single drop of blood. The process is painless.
							</p>
						</div>
						<div className={styles.arrowWrapper}>
							<div className={styles.arrow}>
								<img src={smallArrowSVG} alt="" />
							</div>
						</div>
						<div className={styles.step}>
							<img className={styles.stepIcon} src={step2SVG} alt="" />
							<h3 className={styles.title}>USPS picks up the sample</h3>
							<p className={styles.paragraph}>
								USPS carrier will come to their location to pick up the sample from your mailbox.
							</p>
						</div>
						<div className={styles.arrowWrapper}>
							<div className={styles.arrow}>
								<img src={smallArrowSVG} alt="" />
							</div>
						</div>
						<div className={styles.step}>
							<img className={styles.stepIcon} src={step3SVG} alt="" />
							<h3 className={styles.title}>Get results and take action</h3>
							<p className={styles.paragraph}>
								The results are delivered in 3-5 days. Take action based on the results.
							</p>
						</div>
					</div>
					<div className={styles.abstractFigure}>
						<img src={abstractFigureSVG} alt="" />
					</div>
				</div>
				<Link to="/register" className={styles.requestButton}>
					REQUEST IG ANTIBODY TEST KIT
				</Link>
			</div>
			<div ref={faqRef} className={styles.panel5}>
				<h2 className={styles.title}>Frequently Asked Questions</h2>
				<div className={styles.separator} />
				<p className={styles.paragraph}>
					If your question is not found below, please feel free to email us your question at{' '}
					<a href="mailto:support@siphoxhealth.com" className={styles.link}>
						support@siphoxhealth.com
					</a>
				</p>
				<QuestionsContainer />
			</div>
			<div className={styles.footerWrapper}>
				<footer className={styles.footer}>
					<div className={styles.footerRow1}>
						<div className={styles.col1}>
							<a href="https://siphoxhealth.com/">
								<img src={logoPNG} alt="" />
							</a>
							<div className={styles.linksContainer}>
								<a href="https://siphoxhealth.com/pages/about-us">About</a>
								<a href="https://hub.siphoxhealth.com/">Blog</a>
								<button onClick={() => scrollToRef(faqRef)}>FAQs</button>
								<a href="https://siphoxhealth.com/pages/partner">Enterprise</a>
								<a href="https://siphoxhealth.com/pages/contact-support">Support</a>
								<a href="https://siphoxhealth.com/#biomarkers-section">Biomarkers</a>
							</div>
						</div>
						<div className={styles.col2}>
							<span className={styles.title}>Our mission</span>
							<p>
								By leveraging silicon photonic biosensors, lower the cost of at-home health testing
								by 100x and provide results instantly.
							</p>
						</div>
					</div>
					<div className={styles.separator} />
					<div className={styles.footerRow2}>
						<div className={styles.col1}>
							<span className={styles.title}>Company</span>
							<div className={styles.address}>
								SiPhox, Inc. {'\n'}
								111 Terrace Hall Avenue, Burlington {'\n'}
								MA 01803 US
							</div>
							<img src={certificationsPNG} alt="" />
						</div>
						<div className={styles.col2}>
							<span className={styles.title}>Disclaimer</span>
							<p className={styles.paragraph}>
								The reference ranges and insights presented in the SiPhox Health dashboard are meant
								to improve readability and provide context, however you should consult with your
								physician for a more formal interpretation of your results. No communication from
								SiPhox Health is meant to be or should be taken as medical advice. SiPhox Health is
								a wellness-only service and is not designed to diagnose or treat any disease. If you
								are concerned about any of the data in your dashboard please consult your physician
								immediately.
							</p>
						</div>
					</div>
					<div className={styles.separator} />
					<div className={styles.footerRow3}>
						<div className={styles.policiesContainer}>
							<a href="https://siphoxhealth.com/policies/privacy-policy">Privacy Policy</a>
							<a href="https://siphoxhealth.com/policies/refund-policy">Refund Policy</a>
							<a href="https://siphoxhealth.com/policies/terms-of-service">Terms of Service</a>
							<div className={styles.separator} />
							<div className={styles.socialsContainer}>
								<a href="https://twitter.com/siphoxhealth">
									<img src={twitterSVG} alt="" />
								</a>
								<a href="https://www.facebook.com/siphoxhealth">
									<img src={fbSVG} alt="" />
								</a>
								<a href="https://www.youtube.com/@SiPhoxInc">
									<img src={ytSVG} alt="" />
								</a>
							</div>
						</div>
						<span>©2022, SiPhox Inc</span>
					</div>
				</footer>
			</div>
		</>
	)
}
