import { MedplumClient } from '@medplum/core'
import { MedplumProvider } from '@medplum/react'
import React from 'react'
import ReactDOM from 'react-dom/client'

import App from './App'

const medplumClient = new MedplumClient({
	onUnauthenticated: () => (window.location.href = '/'),
})

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
	<React.StrictMode>
		<MedplumProvider medplum={medplumClient}>
			<App />
		</MedplumProvider>
	</React.StrictMode>
)
